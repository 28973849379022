<template>
  <div class="intro-animation" :class="[{ 'menu-is-open' : menuIsOpen }, { 'isLoading' : loading }]">

    <div class="intro-animation__photos">
      <div class="intro-animation__photos__item intro-animation__photos__item--1">
        <img class="intro-animation__photos__image" src="../assets/photo/argentina-1.png">
      </div>
      <div class="intro-animation__photos__item intro-animation__photos__item--2">
        <img class="intro-animation__photos__image" src="../assets/photo/argentina-2.png">
      </div>
      <div class="intro-animation__photos__item intro-animation__photos__item--3">
        <img class="intro-animation__photos__image" src="../assets/photo/brasile-1.png">
      </div>
      <div class="intro-animation__photos__item intro-animation__photos__item--4">
        <img class="intro-animation__photos__image" src="../assets/photo/chile-2.png">
      </div>
      <div class="intro-animation__photos__item intro-animation__photos__item--5">
        <img class="intro-animation__photos__image" src="../assets/photo/chile-1.png">
      </div>
      <div class="intro-animation__photos__item intro-animation__photos__item--6">
        <img class="intro-animation__photos__image" src="../assets/photo/islanda-2.png">
      </div>
      <div class="intro-animation__photos__item intro-animation__photos__item--7">
        <img class="intro-animation__photos__image" src="../assets/photo/islanda-1.png">
      </div>
    </div>

    <div class="intro-animation__container">
      <div class="intro-animation__title" data-splitting>Carmelo Macri</div>
      <svg class="intro-animation__svg" x="0px" y="0px" viewBox="0 0 1000 1000">
        <path class="intro-animation__svg__path" d="M6.6,624.9c-3.2-20.5,0.7-21,4.6-32.8c3.7-11.7,30.6-62.1,30.6-62.1c29.3-50.4,65-99.8,89-151.1"/>
        <path class="intro-animation__svg__path" d="M13,409.3c40.6-73.3,265-127.6,240.6-33.3C237.9,443.5,109.3,511.5,46.2,534c0,0-22.7,8.3-30.8,7.3"/>
        <path class="intro-animation__svg__path" d="M191.4,544.3c24.4-26.4,69.9-76.8,94.4-122.2c5.9-11.2,18.1-28.9,13.2-38.6c-1-2-2-2.4-4.4-1.5c-24,12.7-42.1,41.1-56.2,62.6c-8.8,14.2-23,37.2-29.8,51.8c-7.8,15.6-16.1,31.3-23.5,47.4c-2,5.9-4.4,12.2-7.3,18.6c-25.9,66,62.6-25.4,71.9-22c-1,5.9-3.9,13.7-2.9,19.6c3.9,15.2,30.8-6.8,38.6-13.7"/>
        <path class="intro-animation__svg__path" d="M321.5,540.3c2-11.7-2.4-20-14.2-15.2c-2.4,1.7-4.6,3.4-7.3,5.9c-14.7,14.7-29.8,38.9-14.2,49.4c3.9,2.7,13.7,0.2,18.6-7.1c2-2.9,13.9-17.1,17.1-31.8c10.8,3.9,20.5,9,25.4,4.6"/>
        <path class="intro-animation__svg__path" d="M432.5,401.5c-17.1,25.4-33.3,51.8-47.9,78.2c0,0-19.6,34.5-27.9,56.7c-2.9,7.8-6.4,15.2-8.3,23c-25.9,80.7,42.1,9.3,62.1-14.2"/>
        <path class="intro-animation__svg__path" d="M347.9,492.9c48.4-12,100.2-12.2,100.2-12.2"/>
        <path class="intro-animation__svg__path" d="M445.5,542.3c2-11.7-2.4-20-14.2-15.2c-2.4,1.7-4.6,3.4-7.3,5.9c-14.7,14.7-29.8,38.9-14.2,49.4c3.9,2.7,13.7,0.2,18.6-7.1c2-2.9,13.9-17.1,17.1-31.8c10.8,3.9,20.5,9,25.4,4.6"/>
        <path class="intro-animation__svg__path" d="M512.2,544.7c-17.1,13.9-33.7,24-49.9,22c-2-1-2.4-1-2-3.4c7.8-18.6,40.1-35.2,56.7-35.2c2,0,2.4,0.5,2.9,2l1,5.9c0,1.5-1,3.4-2.4,3.9L512.2,544.7L512.2,544.7z M522,536.7c0,0-1.2,24-7.8,43.8c-11.5,29.1-44,95.8-66.5,95.4c-26.4-2.9,16.6-54.3,23-62.1c0,0,44-50.1,68.5-62.3s24.4-4.9,24.4-4.9"/>
        <path class="intro-animation__svg__path" d="M585.6,495.6c-10.8,29.3-23,59.2-32.3,89c-0.5,2.4,0,2.4,2,3.4h2c2.4-0.5,2.9-1.5,3.9-2.4c12.7-18.1,22-41.1,42.5-51.3c2,2,3.9,3.9,5.9,6.4"/>
        <path class="intro-animation__svg__path" d="M661.4,524.2c0-2.4,0.5-4.9,0-6.8c-0.5-1.5-0.5-2-2.4-2c-39.1,0-91,91-21,35.7c8.8-7.3,17.6-14.7,26.9-21.5c-4.4,18.1-29.3,48.4-14.7,60.6"/>
        <path class="intro-animation__svg__path" d="M637.9,675.3c3.9-56.2,39.6-117.4,62.6-174.1"/>
        <path class="intro-animation__svg__path" d="M688,530.1c0,0,22.2-30.1,39.4-17.8c17.1,12.2-9.8,29.3-19.6,34.2c-9.8,4.9-48.9,14.7-48.9,14.7"/>
        <path class="intro-animation__svg__path" d="M741.8,547.2c24.4-26.4,69.9-76.8,94.4-122.2c5.9-11.2,18.1-28.9,13.2-38.6c-1-2-2-2.4-4.4-1.5c-24,12.7-42.1,41.1-56.2,62.6c-8.8,14.2-23,37.2-29.8,51.8c-7.8,15.6-16.1,31.3-23.5,47.4c-2,5.9-4.4,12.2-7.3,18.6c-25.9,66,62.6-25.4,71.9-22c-1,5.9-3.9,13.7-2.9,19.6c3.9,15.2,30.8-6.8,38.6-13.7"/>
        <path class="intro-animation__svg__path" d="M851.6,529.1c-6.4,8.8-7.8,13.2-14.2,26.4c-30.3,64.1,56.2-24,60.6-27.4c0,4.4,1.5,8.3-0.5,13.2c-1.5,5.9-5.9,15.2-11.7,25.4c0,0-39.4,64.3-58.4,81.9c-19.1,17.6-58.7,41.1-19.3-11c39.1-52.1,61.1-63.8,88-76.3c2.4-1.5,5.4-2.4,8.8-4.4c16.6-8.3,77.8-36.2,90-26.9"/>
      </svg>
    </div>
  </div>
</template>

<script>
import { preloadImages } from '@/utils'
import Grid from '@/grid'

export default {
  name: 'IntroAnimation',
  mounted() {
    this.$splitting()
    this.startPrelaodImages()
  },
  computed: {
    loading() {
      return this.$store.getters.getLoading
    },
    menuIsOpen() {
      return this.$store.getters.getMenuIsOpen
    }
  },
  methods: {
    startPrelaodImages() {
      preloadImages('.intro-animation__photos__image').then(() => {
        this.$store.state.loading = false
        this.startAnimation()
      })
    },

    createGridAnimation() {
      new Grid(document.querySelector('.intro-animation__photos'));
    },

    startTextAnimation() {
      document.querySelector('.intro-animation__title').classList.add('is-enter')
    },

    endTextAnimation() {
      document.querySelector('.intro-animation__title').classList.add('is-exit')
    },

    startPhotosAnimation() {
      this.$anime({
        targets: '.intro-animation__photos__image',
        translateY: ['110%', 0],
        easing: 'easeInOutSine',
        duration: 1000,
        delay: function(el, i) { return i * 150 },
      });
    },

    startSvgAnimation() {
      this.$anime({
        targets: '.intro-animation__svg__path',
        strokeDashoffset: ['100%', 0],
        easing: 'easeInOutSine',
        duration: 500,
        delay: function(el, i) { return i * 150 },
        direction: 'normal'
      });
    },

    startAnimation() {
      this.createGridAnimation()
      setTimeout(() => this.startTextAnimation(), 1000)
      setTimeout(() => this.startSvgAnimation(), 1500)
      // setTimeout(() => this.endTextAnimation(), 6000)
      setTimeout(() => this.startPhotosAnimation(), 5000)
    },
  }
}
</script>

<style lang="scss">

@import '@/scss/utils.scss';

.intro-animation {
  height: 100vh;
  position: relative;
  transform: scale(1);
  transition: all 1s cubic-bezier(.2, 0, .1, 1);
  width: 100vw;

  &.menu-is-open {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transform: scale(.90);
  }
}

.intro-animation__container {
  left: 25vw;
  pointer-events: none;
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  transition: all .5s ease-in-out;
  width: 50vw;
  z-index: 1;

  &.is-ending {
    left: 50px;
    top: 50px;
    transform: translateY(0);
    width: 200px;
  }

  @media (orientation: portrait) {
    left: 10vw;
    width: 80vw;
  }
}

.intro-animation__title {
  @include title;
  left: 0;
  position: absolute;
  right: 0;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  transition: all .5s ease-in-out;

  @media (orientation: portrait) {
    @include title(medium-portrait);
  }

  .word,
  .char {
    display: inline-block;
  }

  .char {
    opacity: 0;
    transform: translateX(20px);
  }

  &.is-enter .char {
    @keyframes title-enter {
      from {
        opacity: 0;
        transform: translateX(20px);
      }
      to {
        opacity: 1;
        transform: translateX(0);
      }
    }
    animation: title-enter 1s cubic-bezier(.2, 0, .1, 1) both;
    animation-delay: calc(.05s * var(--char-index));
  }

  &.is-exit .char {
    @keyframes title-exit {
      from {
        opacity: 1;
        transform: translateX(0);
      }
      to {
        opacity: 0;
        transform: translateX(-20px);
      }
    }
    animation: title-exit 1s cubic-bezier(.2, 0, .1, 1) both;
    animation-delay: calc(.05s * var(--char-index));
  }
}

.intro-animation__svg {
  left: 12.5%;
  position: relative;
  top: -10px;
  transform: rotate(-10deg);
  width: 80%;
  z-index: 1;
}

.intro-animation__svg__path {
  fill: transparent;
  stroke-dasharray: 100%;
  stroke-dashoffset: 100%;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 6px;
  stroke: color(accent);

  @media (orientation: portrait) {
    stroke-width: 8px;
  }
}

.intro-animation__photos {
  opacity: 1;
  pointer-events: none;
  position: relative;
  top: 0;
  z-index: 0;

  &.is-enter {
    pointer-events: all;
  }
}

.intro-animation__photos__item {
  overflow: hidden;
  position: fixed;
}

.intro-animation__photos__item--1 {
  left: 10vw;
  top: 20vh;
  width: 20vw;

  @media (orientation: portrait) {
    width: 30vw;
    left: 5vw;
    top: 25vh;
  }
}

.intro-animation__photos__item--2 {
  left: 55vw;
  top: 5vh;
  width: 20vw;

  @media (orientation: portrait) {
    width: 30vw;
    top: 15vh;
    left: 60vw;
  }
}

.intro-animation__photos__item--3 {
  left: 65vw;
  top: 70vh;
  width: 15vw;

  @media (orientation: portrait) {
    width: 25vw;
    left: 65vw;
    top: 55vh;
  }
}

.intro-animation__photos__item--4 {
  left: 20vw;
  top: 70vh;
  width: 20vw;

  @media (orientation: portrait) {
    width: 30vw;
    top: 60vh;
    left: 10vw;
  }
}

.intro-animation__photos__item--5 {
  left: 40vw;
  top: 25vh;
  width: 10vw;

  @media (orientation: portrait) {
    width: 20vw;
    top: 30vh;
    left: 45vw;
  }
}

.intro-animation__photos__item--6 {
  left: 75vw;
  top: 40vh;
  width: 10vw;

  @media (orientation: portrait) {
    display: none;
  }
}

.intro-animation__photos__item--7 {
  left: 50vw;
  top: 66vh;
  width: 10vw;

  @media (orientation: portrait) {
    width: 20vw;
    top: 70vh;
  }
}

.intro-animation__photos__image {
  display: block;
  transform: translateY(110%);
}

.intro-animation__scroller {
  position: absolute;
  width: 1px;
  height: 200px;
  background: linear-gradient(180deg, color(background) 0, color(text) 50%, color(background) 100%);
  left: 50%;
  bottom: -100px;

}

</style>
