import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  // {
  //   path: '/countries/:slug',
  //   name: 'country',
  //   component: () => import(/* webpackChunkName: "country" */ '../views/Country')
  // },
  {
    path: '/humans',
    name: 'humans',
    component: () => import(/* webpackChunkName: "humans" */ '../views/Humans')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  }
})

export default router
