<template>
  <div id="carmelomacri">
    <Navigation/>
    <router-view/>
    <svg class="cursor" width="30" height="30" viewBox="0 0 30 30">
      <circle class="cursor__inner" cx="15" cy="15" r="7.5"/>
    </svg>
  </div>
</template>

<script>
import Navigation from "@/components/Navigation"

export default {
  name: 'App',
  components: {
    Navigation
  },
  watch: {
    country() {
      this.startPrelaodImages()
    },
    $route() {
      this.$store.state.loading = true
    }
  },
}
</script>

<style lang="scss">
  @import '@/scss/app.scss';

  .cursor {
    display: none;
  }

  @media (any-pointer: fine) {
    html,
    body {
      cursor: none;
    }

    a {
      cursor: none;
    }

    .cursor {
      display: block;
      left: 0;
      pointer-events: none;
      position: fixed;
      top: 0;
      z-index: 1;
    }
    .cursor__inner {
      fill: color(accent);
      opacity: 1;
      stroke-width: 1px;
      stroke: none;
    }
    .no-js .cursor {
      display: none;
    }
  }
</style>
