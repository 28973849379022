<template>
  <section class="navigation" :class="{ 'is-open' : menuIsOpen }">
    <div class="navigation__button">
      <h1 class="navigation__button__logo cursor--big" data-splitting>
        <router-link to="/">Carmelo Macri</router-link>
      </h1>
      <div class="navigation__button__countries cursor--big" data-splitting>
        <router-link to="/humans">Humans</router-link>
      </div>
      <div class="navigation__button__copy" data-splitting>©2024</div>
      <a href="mailto:carmelomacri@me.com" class="navigation__button__instagram cursor--big" data-splitting>Contact me</a>
    </div>

<!--    <div class="navigation__countries">-->
<!--      <div-->
<!--          v-for="(country, index) in this.countries"-->
<!--          :key="index"-->
<!--          class="navigation__countries__item"-->
<!--      >-->
<!--        <router-link :to="'/countries/' + country.slug">-->
<!--          <span-->
<!--            class="navigation__countries__item__name cursor&#45;&#45;big cursor&#45;&#45;countries"-->
<!--            @mouseover="showImageOnOver(country.slug)"-->
<!--            @mouseleave="removeAllImages()"-->
<!--          >-->
<!--            {{ country.title }}-->
<!--            <span class="navigation__countries__item__number">{{ country.gallery.length }}</span>-->
<!--          </span>-->
<!--        </router-link>-->
<!--      </div>-->
<!--    </div>-->

<!--    <div class="navigation__countries&#45;&#45;photos">-->
<!--      <div-->
<!--          v-for="(country, index) in this.countries"-->
<!--          :key="index"-->
<!--          class="navigation__countries__photos"-->
<!--          :class="'navigation__countries__photos&#45;&#45;' + country.slug"-->
<!--          :style="{ 'background-image': 'url(' + country.gallery[0].url.large + ')' }"-->
<!--      ></div>-->
<!--    </div>-->

  </section>
</template>

<script>
export default {
  name: 'Navigation',
  data() {
    return {
      zIndexImage: 1,
    }
  },
  mounted() {
    this.$nextTick(function () {
      this.$splitting()
      this.initCursor()
      this.startAnimation()
    })
  },
  computed: {
    loading() {
      return this.$store.getters.getLoading
    },
    countries() {
      return this.$store.getters.getCountries
    },
    menuIsOpen() {
      return this.$store.getters.getMenuIsOpen
    }
  },
  updated() {
    this.$nextTick(function () {
      this.initCursor()
    })
  },
  methods: {
    initCursor() {
      var cursor = new this.$cursor(document.querySelector('.cursor'), document.querySelector('.cursor__inner'));

      [...document.querySelectorAll('.cursor--big')].forEach(link => {
        link.addEventListener('mouseenter', () => cursor.enter(link));
        link.addEventListener('mouseleave', () => cursor.leave());
      });
    },
    startLogoAnimation() {
      document.querySelector('.navigation__button__logo').classList.add('is-enter')
    },
    startCountriesAnimation() {
      document.querySelector('.navigation__button__countries').classList.add('is-enter')
    },
    startCopyAnimation() {
      document.querySelector('.navigation__button__copy').classList.add('is-enter')
    },
    startInstagramAnimation() {
      document.querySelector('.navigation__button__instagram').classList.add('is-enter')
    },
    startAnimation() {
      setTimeout(() => this.startLogoAnimation(), 6000)
      setTimeout(() => this.startCountriesAnimation(), 6400)
      setTimeout(() => this.startCopyAnimation(), 6800)
      setTimeout(() => this.startInstagramAnimation(), 7200)
    },
    toggleMenu() {
      this.$store.state.menuIsOpen = !this.$store.state.menuIsOpen
    },
    showImageOnOver(country) {
      let hoveredCountry = document.querySelectorAll('.navigation__countries__photos--' + `${country}`)

      this.removeAllImages()

      this.$anime({
        targets: hoveredCountry,
        scale: ['1.05', '1'],
        translateX: ['50', '0'],
        rotate: ['2', '0'],
        opacity: ['0', '1'],
        easing: 'easeInOutSine',
        duration: 150,
      });

      this.zIndexImage++
    },
    removeAllImages() {
      Array.prototype.forEach.call(document.querySelectorAll('.navigation__countries__photos'), function(el) {
        el.style.opacity = 0
      });
    }
  }
}
</script>

<style lang="scss">

@import '@/scss/utils.scss';

@keyframes text-enter {
  from {
    opacity: 0;
    transform: translateX(20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.word,
.char {
  display: inline-block;
}

.char {
  opacity: 0;
  transform: translateX(20px);
}

.navigation {
  height: 100vh;
  left: 0;
  overflow: hidden;
  position: fixed;
  top: 110%;
  transition: top 1s cubic-bezier(.2, 0, .1, 1);
  width: 100vw;
  z-index: 10;

  &.is-open {
    top: 0;
    transition: top 2s cubic-bezier(.2, 0, .1, 1);
    overflow-y: scroll;
  }
}

.navigation__button__logo {
  @include text;
  color: color(text);
  left: 50px;
  position: fixed;
  text-transform: uppercase;
  top: 50px;
  text-decoration: none;
  z-index: 10;

  @media (orientation: portrait) {
    left: 30px;
    top: 30px;
  }

  &.is-enter .char {
    animation: text-enter 1s cubic-bezier(.2, 0, .1, 1) both;
    animation-delay: calc(.05s * var(--char-index));
  }

  a {
    color: color(text);
    text-decoration: none;
  }
}

.navigation__button__countries {
  @include text;
  color: color(text);
  position: fixed;
  right: 50px;
  text-decoration: none;
  text-transform: uppercase;
  top: 50px;
  z-index: 10;

  @media (orientation: portrait) {
    right: 30px;
    top: 30px;
  }

  &.is-enter .char {
    animation: text-enter 1s cubic-bezier(.2, 0, .1, 1) both;
    animation-delay: calc(.05s * var(--char-index));
  }

  a {
    color: color(text);
    text-decoration: none;
  }
}

.navigation__button__copy {
  @include text;
  text-transform: uppercase;
  position: fixed;
  bottom: 50px;
  left: 50px;
  z-index: 10;

  @media (orientation: portrait) {
    bottom: 30px;
    left: 30px;
  }

  &.is-enter .char {
    animation: text-enter 1s cubic-bezier(.2, 0, .1, 1) both;
    animation-delay: calc(.05s * var(--char-index));
  }
}

.navigation__button__instagram {
  @include text;
  bottom: 50px;
  color: color(text);
  position: fixed;
  right: 50px;
  text-decoration: none;
  text-transform: uppercase;
  z-index: 10;

  @media (orientation: portrait) {
    bottom: 30px;
    right: 30px;
  }

  &.is-enter .char {
    animation: text-enter 1s cubic-bezier(.2, 0, .1, 1) both;
    animation-delay: calc(.05s * var(--char-index));
  }
}

.navigation__countries {
  left: 10vw;
  opacity: 0!important;
  padding-bottom: 10vw;
  padding-top: 10vw;
  pointer-events: none;
  position: relative;
  transition: opacity 1s cubic-bezier(.2, 0, .1, 1);
  width: 80vw;
  z-index: 10;

  .navigation.is-open & {
    opacity: 1!important;
    pointer-events: all;
  }
}

.navigation__countries__item {
  @include title(small);
}

.navigation__countries__item__name {
  color: color(text);
  display: inline-block;
  position: relative;
  text-decoration: none;
  transition: all .3s cubic-bezier(.2, 0, .1, 1);

  &:hover {
    color: color(accent);
  }
}

.navigation__countries__item__number {
  @include text(large);
  position: absolute;
  top: .55vw;
  right: -2vw;
}

.navigation__countries--photos {
  opacity: 0;
  pointer-events: none;
  transition: all 1s cubic-bezier(.2, 0, .1, 1);
  z-index: 5;

  .navigation.is-open & {
    opacity: .2;
    pointer-events: all;
  }
}

.navigation__countries__photos {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 80vh;
  left: 20vw;
  opacity: 0;
  position: fixed;
  top: 10vh;
  width: 60vw;
  z-index: 0;
  transition: all .2s cubic-bezier(.2, 0, .1, 1);

  img {
    width: 100%;
  }
}

</style>
