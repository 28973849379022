<template>
  <section class="home">
    <IntroAnimation/>
  </section>
</template>

<script>
// @ is an alias to /src
import IntroAnimation from '@/components/IntroAnimation.vue'

export default {
  name: 'Home',
  components: {
    IntroAnimation,
  },
}
</script>

<style lang="scss" scoped>
@import '@/scss/utils.scss';

.home {
  height: 100vh;
  overflow-y: scroll;
}

</style>