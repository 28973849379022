import { map, lerp, getMousePos, calcWinsize, getRandomNumber } from '@/utils';
import anime from 'animejs';

// Calculate the viewport size
let winsize = calcWinsize();
window.addEventListener('resize', () => winsize = calcWinsize());

// Track the mouse position
let mousepos = {x: winsize.width/2, y: winsize.height/2};
window.addEventListener('mousemove', ev => mousepos = getMousePos(ev));

class GridItem {
    constructor(el) {
        this.DOM = {el: el};
        this.move();
    }
    // Move the items when moving the cursor
    move() {
        // amounts to move in each axis
        let translationVals = {tx: 0, ty: 0};
        // get random start and end movement boundaries
        const xstart = getRandomNumber(10, 20);
        const ystart = getRandomNumber(10, 20);

        // infinite loop
        const render = () => {
            // Calculate the amount to move.
            // Using linear interpolation to smooth things out.
            // Translation values will be in the range of [-start, start] for a cursor movement from 0 to the window's width/height
            translationVals.tx = lerp(translationVals.tx, map(mousepos.x, 0, winsize.width, -xstart, xstart), 0.3);
            translationVals.ty = lerp(translationVals.ty, map(mousepos.y, 0, winsize.height, -ystart, ystart), 0.3);

            anime({
              targets: this.DOM.el,
                translateX: translationVals.tx,
                translateY: translationVals.ty,
                duration: 0,
                easing: 'linear',
            })

            requestAnimationFrame(render);
        }
        requestAnimationFrame(render);
    }
}

export default class Grid {
    constructor(el) {
        this.DOM = {el: el};
        this.gridItems = [];
        this.items = [...this.DOM.el.querySelectorAll('.intro-animation__photos__item')];
        this.items.forEach(item => this.gridItems.push(new GridItem(item)));
    }
}