import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import VueAnalytics from 'vue-analytics'


import anime from 'animejs';

import splitting from 'splitting'

import Cursor from './cursor'

import VueProgressiveImage from 'vue-progressive-image'
Vue.use(VueProgressiveImage)

Vue.prototype.$anime = anime;
Vue.prototype.$splitting = splitting;
Vue.prototype.$cursor = Cursor;
Vue.config.productionTip = false

new Vue({
  store,
  router,
  created() {
    this.$store.dispatch('loadData') // dispatch loading
  },
  render: h => h(App)
}).$mount('#carmelomacri')

Vue.use(VueAnalytics, {
  id: 'UA-180153591-1',
  router
})