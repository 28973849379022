import axios from 'axios'
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const baseUrl = 'http://www.emanuelemacri.it/back/wp-json/api/v2/countries'

export default new Vuex.Store({
    state: {
        countries: [],
        loading: true,
        menuIsOpen: false
    },
    getters: {
        getCountries: state => {
            return state.countries
        },
        getLoading: state => {
            return state.loading
        },
        getMenuIsOpen: state => {
            return state.menuIsOpen
        }
    },
    actions: {
        loadData({
            commit
        }) {
            axios.get(baseUrl).then(response => {
                commit('updateCountries', response.data)
            })
        }
    },
    mutations: {
        updateCountries(state, countries) {
            state.countries = countries
        },
        changeLoadingState(state, loading) {
            state.loading = loading
        },
        changeMenuState(state, menuIsOpen) {
            state.menuIsOpen = menuIsOpen
        }
    }
})

